@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Muli";
  src: local("Muli"),
    url("./assets/fonts/muli/muli-webfont.woff") format("woff"),
    url("./assets/fonts/muli/muli-webfont.woff2") format("woff2");
}

:root {
  --primary-color: #FFFFFF;
  --secondary-color: #FF0000;
  --cadmium-color: #D22B2B;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Muli", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#decoration {
  position: absolute;
  left: -59px;
  display: grid;
  gap: 3px;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: repeat(10, 1fr);
  place-items: stretch;
  height: 90px;
  width: 500px;
  transform: rotate(-55deg);
  transition: opacity 0.5s ease-in-out;
  opacity: 0.5;
}

.indexClass {
  z-index: -1;
}

.pill {
  display: block;
  background: red;
  opacity: 0.75;
  border-radius: 1000px;
  transform: translateX(-250px);
}

#pill-1 {
  grid-column: 4 / span 8;
  grid-row: 5 / span 3;
}

#pill-2 {
  grid-column: 10 / span 4;
  grid-row: 6 / span 1;
}

#pill-3 {
  grid-column: 8 / span 3;
  grid-row: 3 / span 1;
}

#pill-4 {
  grid-column: 4 / span 3;
  grid-row: 3 / span 1;
}

#pill-5 {
  grid-column: 6 / span 4;
  grid-row: 9 / span 1;
}

#pill-6 {
  grid-column: 11 / span 1;
  grid-row: 9 / span 1;
}

#pill-7 {
  grid-column: 13 / span 2;
  grid-row: 10 / span 1;
}

#pill-8 {
  grid-column: 13 / span 3;
  grid-row: 8 / span 1;
}

#pill-9 {
  grid-column: 14 / span 3;
  grid-row: 4 / span 1;
}

#pill-10 {
  grid-column: 12 / span 1;
  grid-row: 4 / span 1;
}

#decoration > #pill-1 {
  animation: 20s infinite pill-float linear;
  animation-delay: 0.2s;
  /* animation-iteration-count: infinite;
    animation-direction: normal; */
}

#decoration > #pill-2 {
  animation: 30s infinite pill-float linear;
}

#decoration > #pill-3 {
  animation: 35s infinite pill-float linear;
  animation-delay: 0.1s;
}

#decoration > #pill-4 {
  animation: 25s infinite pill-float linear;
  animation-delay: 0.3s;
}

#decoration > #pill-5 {
  animation: 40s infinite pill-float linear;
  animation-delay: 0.4s;
}

#decoration > #pill-6 {
  animation: 30s infinite pill-float linear;
  animation-delay: 0.5s;
}

#decoration > #pill-7 {
  animation: 25s infinite pill-float linear;
  animation-delay: 0.6s;
}

#decoration > #pill-8 {
  animation: 20s infinite pill-float linear;
  animation-delay: 0.7s;
}

#decoration > #pill-9 {
  animation: 40s infinite pill-float linear;
  animation-delay: 0.8s;
}

#decoration > #pill-10 {
  animation: 35s infinite pill-float linear;
  animation-delay: 0.4s;
}

@keyframes pill-float {
  0% {
    transform: translateX(-250px);
  }

  100% {
    transform: translateX(250px);
  }
}

.flip-boxes {
  justify-content: center;
}

.flip-box {
  display: flex;
  align-content: stretch;
  min-height: 200px;
  flex-wrap: wrap;
  position: relative;
  border: 10px solid transparent;
  padding: 0;
  border-top: 0;
  -webkit-perspective: 1000;
  perspective: 1000;
}
.flip-box:hover .back {
  transform: rotateY(0deg);
  z-index: 10;
}
.flip-box:hover .front {
  transform: rotateY(180deg);
  z-index: -1;
}
.flip-box .back, .flip-box .front {
  position: relative;
  background-color: #FFFFFF;
  color: #222732;
  display: flex;
  justify-content: center;
  align-content: center;
  flex: 0 0 100%;
  -webkit-transition: all 1s cubic-bezier(0.5, 1, 0.5, 1);
  transition: all 1s cubic-bezier(0.5, 1.3, 0.5, 1.3);
  transform-style: preserve-3d;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}
.flip-box .back {
  background-color: #FFFFFF;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  transform: rotateY(-180deg);
}
.flip-box .back:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 30px;
  border: 15px solid transparent;
  border-bottom-color: white;
  border-left-color: white;
}
.flip-box .front {
  z-index: 10;
}
.flip-box .front .content {
  font-size: 2rem;
}
.flip-box .front:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  border: 15px solid #cecece;
  border-bottom-color: white;
  border-right-color: white;
}
.flip-box .content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -webkit-transform: translateZ(50px);
  transform: translateZ(50px);
  text-shadow: 0px 0px 2px black;
}
